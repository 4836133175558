import React from 'react';
import { useTheme } from 'emotion-theming';
import { useTranslation } from 'react-i18next';
import person from '../assets/img/tsakalakos1.jpg';
import maintenance from '../assets/img/coming2.png';


import { ContentContainer, ImageCoorporation, Flex, DisplayLogoHeader, ListContainer, ListField, SpanField } from '../styles';


const MainBody = (): JSX.Element => {
    const { t } = useTranslation('website');
    const { colors, fontSizes } = useTheme();

    return (
        <Flex flexDirection="column" justifyContent="center" width="100%" mt="30px" height="680px" pl="60px" md={{ height: "400px", flexDirection: "column", paddingLeft: "0px" }} lg={{ height: "560px" }}>
            <ImageCoorporation backgroundImage={`url(${person})`} width="100%" backgroundSize="contain" backgroundPosition="left">
                <ContentContainer justifyContent="center" alignItems="center" height="100%" md={{ alignItems: "flex-end"}}>
                    <Flex flexDirection="column" textAlign="center" alignItems="center" justifyContent="center" pl="293px" md={{ justifyContent: "flex-end", paddingLeft: "0px" }}>
                        <DisplayLogoHeader src={maintenance} alt="" width="300px" md={{ width: "250px", marginRight: "40px" }} sm={{ width: "200px", marginRight: "30px" }} />
                    </Flex>
                </ContentContainer>
            </ImageCoorporation>
        </Flex>
    )
};

export default MainBody;
