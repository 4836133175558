import styled from '@emotion/styled';
import Dropdown from 'react-select';


export const bp = {
  xs: 300,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

export const mq = (n:string) => {
  // @ts-ignore
  const bpArray = Object.keys(bp).map((key) => [key, bp[key]])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export const maxmq = (n:string) => {
  // @ts-ignore
  const bpArray = Object.keys(bp).map((key) => [key, bp[key]])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (max-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export const modalStyles = ({ colors }: any) =>({
    overlay: {
      backgroundColor: colors.darkNavyBlue,
    },
    content: {
      width: '80%',
      border: '0px',
      background: 'transparent',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '8px',
      padding: "15px",
      height: '80%',
    }
  })

export const DisplayLogoHeader = styled('img')<any>({
    backgroundColor: 'transparent',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center'
  }, ({ flexDirection, alignItems, justifyContent, width, height, transform, mt, md, lg,sm }) => ({ 
      flexDirection, 
      alignItems, 
      justifyContent, 
      marginTop: mt,
      transform,
      width,
      height,
      [maxmq('lg')]: {
        ...lg
        },
      [maxmq('md')]: {
        ...md
        },
      [maxmq('sm')]: {
          ...sm
          },
    }))
  

export const Layout = styled('div')<any>({
  width: '100%',
  height: '100%',
  backgroundColor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  alignItems: 'center',
  fontSize: '10px',
  [mq('xs')]: {
    fontSize: '10px'
  },
  [mq('sm')]: {
  },
  [mq('md')]: {

  },
  [mq('lg')]: {
    fontSize: '12px'
  },
  [mq('xl')]: {
  }
}, ({ flexDirection, alignItems, justifyContent, mt }) => ({ flexDirection, alignItems, justifyContent, marginTop: mt }))


export const Flex = styled('div')<any>({
  display: 'flex'
}, ({ flexDirection, alignItems, justifyContent, height, textAlign, pl, mt, mb, mr, sm, md, lg, xs  }) => ({ 
  flexDirection, 
  alignItems, 
  justifyContent, 
  textAlign,
  marginTop: mt, 
  marginBottom: mb,
  marginRight: mr,
  paddingLeft: pl,
  height,
  [maxmq('lg')]: {
    ...lg
  },
  [maxmq('md')]: {
    ...md
  },
  [maxmq('sm')]: {
    ...sm
  },
  [maxmq('xs')]: {
    ...xs
  }
 }))

export const ContentContainer = styled('div')<any>({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box'
}, ({ width, height, bg, mt, mr, mb, justifyContent, borderRadius, flexDirection, alignItems, padding, pr, pt, pb, pl, md, lg, sm }) => ({ 
  width,
  height, 
  backgroundColor: bg, 
  marginTop: mt, 
  marginRight: mr,
  marginBottom: mb,
  justifyContent,
  flexDirection,
  borderRadius,
  alignItems, 
  padding,
  paddingRight: pr,
  paddingLeft: pl,
  paddingTop: pt,
  paddingBottom: pb,
  [maxmq('lg')]: {
    ...lg
  },
  [maxmq('md')]: {
    ...md
  },
  [maxmq('sm')]: {
    ...sm
  }
}))


export const ContentItem = styled('div')<any>({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative'
}, ({ width, height, bg, mt, mr, mb, bottom, right, justifyContent, position, alignItems, padding, pr, pt, pb, pl, md, sm, lg}) => ({ 
  width,
  height, 
  backgroundColor: bg, 
  marginTop: mt, 
  marginRight: mr,
  marginBottom: mb,
  justifyContent,
  alignItems, 
  padding,
  position,
  bottom,
  right,
  paddingRight: pr,
  paddingLeft: pl,
  paddingTop: pt,
  paddingBottom: pb,
  [maxmq('lg')]: {
    ...lg
},
  [maxmq('md')]: {
      ...md
  },
  [maxmq('sm')]: {
    ...sm
},
}))

export const TextField = styled('p')<any>({
  margin: 0,
  lineHeight: '1.4em',
  borderRadius: '8px',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
}, ({ fontSize, color, width, height, lineHeight, fontWeight, mr, mt, mb, ml, textAlign, position, lg, margin, padding, pt, pb, pr, pl, md, fontFamily, borderColor, sm, xl, ...rest }) => ({
  fontSize,
  color,
  width,
  height,
  lineHeight,
  fontWeight,
  textAlign,
  position,
  marginRight: mr,
  marginTop: mt,
  marginBottom: mb,
  marginLeft: ml,
  margin,
  padding,
  paddingTop: pt,
  paddingBottom: pb,
  paddingLeft: pl,
  paddingRight: pr,
  fontFamily,
  borderColor,
  [maxmq('xl')]: { ...xl },
  [maxmq('lg')]: { ...lg },
  [maxmq('md')]: { ...md },
  [maxmq('sm')]: { ...sm},
  ...rest
}))


export const ListContainer= styled('ul')<any>({
  margin: 0,
  lineHeight: '1.4em',
  borderRadius: '8px',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
}, ({ fontSize, color, width, height, lineHeight, fontWeight, mr, mt, mb, ml, textAlign, position, lg, margin, padding, pt, pb, pr, pl, md, fontFamily, borderColor, sm, xl, ...rest }) => ({
  fontSize,
  color,
  width,
  height,
  lineHeight,
  fontWeight,
  textAlign,
  position,
  marginRight: mr,
  marginTop: mt,
  marginBottom: mb,
  marginLeft: ml,
  margin,
  padding,
  paddingTop: pt,
  paddingBottom: pb,
  paddingLeft: pl,
  paddingRight: pr,
  fontFamily,
  borderColor,
  [maxmq('xl')]: { ...xl },
  [maxmq('lg')]: { ...lg },
  [maxmq('md')]: { ...md },
  [maxmq('sm')]: { ...sm}
}))


export const ListField = styled('li')<any>({
  margin: 0,
  lineHeight: '1.4em',
  borderRadius: '8px',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
}, ({ fontSize, color, width, height, lineHeight, fontWeight, mr, mt, mb, ml, textAlign, position, lg, margin, padding, pt, pb, pr, pl, md, fontFamily, borderColor, sm, xl, ...rest }) => ({
  fontSize,
  color,
  width,
  height,
  lineHeight,
  fontWeight,
  textAlign,
  position,
  marginRight: mr,
  marginTop: mt,
  marginBottom: mb,
  marginLeft: ml,
  margin,
  padding,
  paddingTop: pt,
  paddingBottom: pb,
  paddingLeft: pl,
  paddingRight: pr,
  fontFamily,
  borderColor,
  [maxmq('xl')]: { ...xl },
  [maxmq('lg')]: { ...lg },
  [maxmq('md')]: { ...md },
  [maxmq('sm')]: { ...sm}
}))

export const SpanField = styled('span')<any>({
  margin: 0,
  lineHeight: '1.4em',
  borderRadius: '8px',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
}, ({ fontSize, color, width, height, lineHeight, fontWeight, mr, mt, mb, textAlign, position, lg, margin, padding, pt, pb, pr, pl, md, fontFamily, borderColor, sm, ...rest }) => ({
  fontSize,
  color,
  width,
  height,
  lineHeight,
  fontWeight,
  textAlign,
  position,
  marginRight: mr,
  marginTop: mt,
  marginBottom: mb,
  margin,
  padding,
  paddingTop: pt,
  paddingBottom: pb,
  paddingLeft: pl,
  paddingRight: pr,
  fontFamily,
  borderColor,
  [maxmq('lg')]: { ...lg },
  [maxmq('md')]: { ...md },
  [maxmq('sm')]: { ...sm},
  ...rest
}))

export const Button = styled('button')<any>({
  margin: 0,
  lineHeight: '1.4em',
  textAlign: 'center',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  borderColor: 'transparent',
  cursor: 'pointer',
  outline: 'none'
}, ({ fontSize, color, bg, width, lineHeight, fontWeight, mr, lg, md, sm, fontFamily, borderColor, ...rest }) => ({
  fontSize,
  color,
  background: bg,
  width,
  lineHeight,
  fontWeight,
  marginRight: mr,
  fontFamily,
  borderColor,
  [mq('lg')]: { ...lg },
  [mq('md')]: { ...md },
  [mq('sm')]: { ...sm },
  ...rest,
  '&:disabled': {
    opacity: 0.6
  }
}))

export const ImageCoorporation = styled('div')<any>({
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '80%',
  backgroundSize: 'cover',
  height: '100%'
}, ({ color, bg, width, mr, lg, md, sm, xl, xs, padding, backgroundImage, backgroundPosition, backgroundSize, height, minHeight }) => ({
  color,
  background: bg,
  width,
  height,
  marginRight: mr,
  padding,
  backgroundImage,
  minHeight,
  backgroundPosition,
  backgroundSize,
  [maxmq('xl')]: { ...xl },
  [maxmq('lg')]: { ...lg },
  [maxmq('md')]: { ...md },
  [maxmq('sm')]: { ...sm },
  [maxmq('xs')]: { ...xs }
}))

export const DropdownContent = styled('option')<any>({
  lineHeight: '1.4em',
  textAlign: 'center',
  posistion: 'relative',
  borderRadius: '5px',
  borderColor: 'transparent',
  cursor: 'grab',
  display: 'flex'
}, ({ fontSize, color, bg, width, lineHeight, fontWeight, mr, lg, md, fontFamily, borderColor }) => ({
  fontSize,
  color,
  backgroundColor: bg,
  width,
  lineHeight,
  fontWeight,
  marginRight: mr,
  fontFamily,
  borderColor,
  [mq('lg')]: { ...lg },
  [mq('md')]: { ...md }
}))

export const DropdownStyled = styled(Dropdown)<any>({
  lineHeight: '1.4em',
  textAlign: 'center',
  posistion: 'relative',
  borderRadius: '5px',
  borderColor: 'transparent',
  cursor: 'grab',
  fontFamily: 'GT Pressura Regular',
  outline: 'none',
  textAlignLast: 'center'
}, ({ fontSize, color, bg, width, lineHeight, fontWeight, mr, lg, md, fontFamily, borderColor, padding, borderRadius}) => ({
  fontSize,
  color,
  backgroundColor: bg,
  width,
  lineHeight,
  fontWeight,
  marginRight: mr,
  fontFamily,
  borderColor,
  padding,
  borderRadius,
  [mq('lg')]: { ...lg },
  [mq('md')]: { ...md },
  '&:disabled': {
    opacity: 0.4
  },
  '&:focus': {
    outline: 'none'
  }
}))

export const TextInputContainer = styled('div')<any>({
  textAlign: 'start',
  height: '54px',
  display: 'flex',
  borderRadius: '8px',
  border: 'solid 1px',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
  fontFamily: 'GT Pressura Regular',
  margin: '8px 2px',
}, ({ fontSize, color, bg, width, lineHeight, fontWeight, mr, lg, md, pl, fontFamily, theme, error }) => ({
  fontSize,
  color,
  backgroundColor: bg,
  width,
  lineHeight,
  fontWeight,
  marginRight: mr,
  fontFamily,
  paddingLeft: pl,
  border: error ? `solid 2px ${theme.colors.error}` : `solid 1px ${theme.colors.darkBlue}`,
  borderRadius: error ? `9px` : `8px`,
  [mq('lg')]: { ...lg },
  [mq('md')]: { ...md },
  '&:focus-within': {
    border: `solid 2px ${theme.colors.primary}`,
    borderRadius: '9px'
  }
}))

export const TextInput = styled('input')<any>({
  textAlign: 'start',
  posistion: 'relative',
  borderColor: 'transparent',
  backgroundColor: 'transparent',
  fontFamily: 'GT Pressura Regular',
  paddingLeft: '7px',
  borderRadius: '8px',
  outline: 'none'
}, ({ fontSize, color, bg, width, lineHeight, fontWeight, mr, lg, md, fontFamily, theme }) => ({
  fontSize,
  color,
  backgroundColor: bg,
  width,
  lineHeight,
  fontWeight,
  marginRight: mr,
  fontFamily,
  [mq('lg')]: { ...lg },
  [mq('md')]: { ...md },
  '::placeholder': {
    color: theme.colors.gray900,
  },
  '&:disabled': {
    opacity: 0.7
  },
}))
