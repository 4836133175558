const forms = {
  input: {
    color: 'secondary',
    borderRadius: 'medium',
    outline: 'none',
  },
  label: {
    color: 'secondary',
    fontSize: 'small-medium',
    fontWeight: 'bold',
    mb: 1,
  },
  textarea: {
    outline: 'none',
    color: 'secondary',
    borderRadius: 'medium',
    maxHeight: '150px',
    maxWidth: '100%',
    minWidth: '100%',
  },
  switch: {
    bg: 'gray300',
    color: 'transparent',
    borderColor: 'secondary',
    '&[aria-checked=true]': {
      bg: 'secondary',
      borderColor: 'secondary',
    },
    thumb: {
      bg: 'white',
      borderColor: 'secondary',
    },
  },
  radio: {
    color: 'gray200',
    'input:checked ~ &': {
      color: 'secondary',
    },
    'input:focus ~ &': {
      bg: 'transparent',
    },
  },
};

export default forms;
