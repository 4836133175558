import colors from './colors';
import shadows from './shadows';
import typography from './typography';
import variants from './variants';
import forms from './forms';
import buttons from './buttons';

type radii = 'none' | 'small' | 'medium' | 'large' | 'pill' | 'circle';

export interface Theme {
  colors: typeof colors;
  shadows: typeof shadows;
  fontWeights: typeof typography['fontWeights'];
  fontSizes: typeof typography['fontSizes'];
  lineHeights: typeof typography['lineHeights'];
  letterSpacings: typeof typography['letterSpacings'];
  space: number[];
  radii: { [key in radii]: number };
  breakpoints: any;
  variants: typeof variants;
  forms: typeof forms;
  buttons: typeof buttons;
}

const breakpoints: any = ['32em', '44em', '66em'];

// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];

export const theme: Theme = {
  buttons,
  variants,
  forms,
  breakpoints,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  colors,
  shadows,
  radii: {
    none: 0,
    small: 2,
    medium: 4,
    large: 6,
    pill: 40,
    circle: 99999,
  },
  ...typography,
};
