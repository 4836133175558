import React, { createContext } from 'react';
import app from 'firebase/app';

export const FirebaseContext = createContext(app);
export const FirebaseUserID = () => createContext({
    userID: null,
    setUserID: () => { },
});

export const initFirebaseApp: any = app.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const FirebaseProvider = ({ children }: { children: any}) => {
    return (
        <FirebaseContext.Provider value={initFirebaseApp}>
            { children }
        </FirebaseContext.Provider>
    )
}

export default FirebaseProvider;
