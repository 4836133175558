import React from 'react'

import {
  ContentContainer, Layout
} from '../../styles';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MainBody from '../../components/MainBody';
import SocialMedia from '../../components/SocialMedia';


const LandingPage = (): JSX.Element => {
  
  return ( 
    <Layout justifyContent="center">  
      <ContentContainer>
          <Header />
          <MainBody />
          <SocialMedia />
          <Footer /> 
      </ContentContainer>
    </Layout>
  )
}

export default LandingPage;
