import React from 'react'
import { useTheme } from 'emotion-theming';
import { useTranslation } from 'react-i18next';
import { ContentItem, TextField, Flex, DisplayLogoHeader} from '../styles';
import facebook from '../assets/img/facebook2.svg';
import instagram from '../assets/img/instagram2.svg';
import youtube from '../assets/img/youtube2.svg';
import email from '../assets/img/email3.svg';
import phone from '../assets/img/phone2.svg';
import spotify from '../assets/img/spotify.svg';
import itunes from '../assets/img/itunes.svg';



const SocialMedia = (): JSX.Element => {
    const { t } = useTranslation('website');
    const { colors, fontSizes } = useTheme();
    return (
        <ContentItem bg="transparent" height="270px" md={{ height: "150px", justifyContent: "flex-start", marginTop: "10px"}}>
            <Flex flexDirection="row" mt="20px" >
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://www.youtube.com/c/PanayotisTsakalakosOfficial" target="_blank" >
                    <DisplayLogoHeader width="25px" src={youtube} alt=""  md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://www.facebook.com/P.Tsakalakos" target="_blank" >
                    <DisplayLogoHeader width="25px" src={facebook} alt=""  md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://www.instagram.com/ptsakalakos/" target="_blank" >
                    <DisplayLogoHeader width="25px" src={instagram} alt=""  md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://open.spotify.com/artist/4hqlPPYRxXwBLDJbpoREmf" target="_blank" >
                    <DisplayLogoHeader width="25px" src={spotify} alt=""  md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://music.apple.com/us/artist/panagiotis-tsakalakos/451465384" target="_blank" >
                    <DisplayLogoHeader width="25px" src={itunes} alt=""  md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="mailto:panayotis.Tsakalakos@gmail.com" target="_blank" >
                    <DisplayLogoHeader width="25px" src={email} alt="" md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' color={colors.greyDark}
                    href="tel:+306945046178" target="_blank" >
                    <DisplayLogoHeader width="25px" src={phone} alt="" md={{ width: "20px"}} />
                </ContentItem>
            </Flex>
            <Flex flexDirection="row" mt="30px" justifyContent="center">
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://www.youtube.com/brokenradiotv" target="_blank" >
                    <DisplayLogoHeader width="25px" src={youtube} alt="" md={{ width: "20px"}} />
                </ContentItem>
                <ContentItem as='a' mr="17px" color={colors.greyDark} lg={{ marginRight: "14px"}}
                    href="https://www.facebook.com/brokenradiogr" target="_blank" >
                    <DisplayLogoHeader width="25px" src={facebook} alt=""  md={{ width: "20px"}}/>
                </ContentItem>
                <ContentItem as='a' color={colors.greyDark}
                    href="https://www.instagram.com/broken_radio_gr/" target="_blank" >
                    <DisplayLogoHeader width="25px" src={instagram} alt="" md={{ width: "20px"}} />
                </ContentItem>
            </Flex>
        </ContentItem>
    )
};

export default SocialMedia;