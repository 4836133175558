const typography = {
  fontSizes: {
    inherit: 'inherit',
    '3xsmall': '0.5rem',
    '2xsmall': '0.563rem',
    'xsmall': '0.625rem',
    small: '0.75rem',
    'smallmedium': '0.813rem',
    medium: '0.875rem',
    'mediumlarge': '0.938rem',
    large: '1rem',
    'xlarge': '1.125rem',
    '2xlarge': '1.25rem',
    '22xlarge': '1.375rem',
    '3xlarge': '1.5rem',
    '26xlarge': '1.625rem',
    '4xlarge': '1.75rem',
    '5xlarge': '1.875rem',
    '6xlarge': '2.188rem',
    '50xlarge': '3.125rem',
    '7xlarge': '3.438rem',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
  fonts: {
    bold: 'GT Pressura Bold',
    primary: 'GT Pressura Regular, DM Sans, system-ui, sans-serif',
    mono: `"GT Pressura Regular", "Roboto Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    tight: '1.25',
    snug: '1.375',
    typical: '1.5',
    relaxed: '1.625',
    loose: '2',
  },
  letterSpacings: {
    normal: 'normal',
    relaxed: '1.5px',
  },
};

export default typography;
