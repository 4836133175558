import React from 'react'
import { useTranslation } from 'react-i18next';
import { ContentItem, TextField } from '../styles';
import { useTheme } from 'emotion-theming';


const Footer = ():JSX.Element => {
  const { t } = useTranslation('vasilobeata');
  const { fontSizes, colors } = useTheme();
  return (
    <ContentItem height={120} bg={colors.blue}>
        {/* <TextField textDecoration="underline" as="a" textAlign="center" color={colors.greyDark} fontSize={fontSizes.medium} md={{ fontSize: fontSizes.small, paddingBottom: '40px' }} lineHeight="14px"
          href="https://thebeat.co/gr/terms/vasilompita/"
          target="_blank"
          onClick={() => ReactGA.event({
            category: 'Button',
            action: 'T&Cs-Vasilobeata',
            label: 'Terms & Conditions link opened'
          })}>
          {t('Footer.tc')}
        </TextField> */}

      <TextField position="absolute" as="div" right={0} textAlign="center" color={colors.greyDark} paddingRight={22} fontSize={fontSizes.medium} md={{ fontSize: fontSizes.small }}>
        2020 © Tsakalakos
      </TextField>

      {/* <ContentItem position="absolute" as="a" right={0} textAlign="center" olor={colors.greyDark} pr={22}
        target="_blank" md={{  bottom: 0, paddingBottom: "24px" }}>
        Panagiotis Tsakalakos
      </ContentItem> */}
    </ContentItem>
  )
}

export default Footer
