import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import website from "./translations/en/website.json";

const setTranslations = () => {
    i18n.use(LanguageDetector).init({
        resources: { 
            en: {
                website: website
            },
        },
        lng: "en",
        fallbackLng: "en",
        debug: true,

        interpolation: {
            escapeValue: false,
        },

        react: {
            wait: true
        }
    });
    return i18n;
}

export default setTranslations;
