import React from 'react';
import { ThemeProvider as EmotionTheming } from 'emotion-theming';
import { theme as defaultTheme, Theme } from '../theme';

interface ThemeProviderProps {
  /** The theme to pass down to the library components */
  theme?: Theme;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme }) => (
  <EmotionTheming theme={theme || defaultTheme}>
    <React.Fragment>{children}</React.Fragment>
  </EmotionTheming>
);

export default ThemeProvider;
