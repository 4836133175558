import React from 'react';
import { Route, Switch } from 'react-router-dom';

import LandingPage from './views/LandingPage';


const PageLayout: React.FC = () => {
  return (
    <React.Fragment>
        <Switch>
            <Route component={LandingPage} /> 
        </Switch>
    </React.Fragment>
  );
};

export default PageLayout;
