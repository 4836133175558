const variants = {
  card: {
    p: 2,
    bg: 'gray50',
    boxShadow: 'dark100',
    borderRadius: 'medium',
  },
};

export default variants;
