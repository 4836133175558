import React from 'react';
import { Router } from 'react-router-dom';
import { History } from 'history';

import './App.css';
import Routes from './Routes';
import FirebaseProvider from './utils/Firebase';
import ThemeProvider from './utils/ThemeProvider';


interface AppProps {
  history: History;
}

const App: React.FC<AppProps> = ({ history }) => {
  return (
    <FirebaseProvider>
      <ThemeProvider>
        <Router history={history}>
            <Routes />
        </Router>
      </ThemeProvider>
    </FirebaseProvider>
  );
}

export default App;
