import React from 'react';

import { ContentItem, ContentContainer, ImageCoorporation, Button, DisplayLogoHeader, modalStyles } from '../styles';
import header from '../assets/img/TsakalakosName.svg';

const Header = (): JSX.Element => {

  return (
    <ContentItem>
        <ContentContainer justifyContent="center" alignItems="center">
              <DisplayLogoHeader src={header} alt="" width="80%"  md={{ width: "100%" }}/>
        </ContentContainer>
    </ContentItem>
  )
};

export default Header;
