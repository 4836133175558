const colors = {
  transparent: 'transparent',
  background: '#fff',

  white: '#ffffff',

  primary900: '#006a3c',
  primary800: '#008859',
  primary700: '#009967',
  primary600: '#00aa78',
  primary500: '#00b885',
  primary400: '#00c696',
  primary300: '#23D2AA',
  primary200: '#76e0c2',
  primary100: '#afecda',
  primary50: '#dff7f1',
  primary: '#23D2AA',

  secondary900: '#006400',
  secondary800: '#008300',
  secondary700: '#141332',
  secondary600: '#2ca613',
  secondary500: '#37b51e',
  secondary400: '#5ac148',
  secondary300: '#7acc6b',
  secondary200: '#a2da97',
  secondary100: '#c7e8c0',
  secondary50: '#e7f6e5',
  secondary: '#141332',

  gray: '#dddde5',
  gray50: '#F6F6F6',
  gray100: '#D2D2D2',
  gray200: '#BDBDBD',
  gray300: '#ABABAB',
  gray450: '#6E7785',
  gray500: '#626E80',
  gray600: '#716F6F',
  gray800: '#4F4F4F',
  gray900: '#8c8c9e',
  grayLight: '#c0c0cb',
  greyDark: '#413f60',
  
  blueSmooth: '#CCE8F1',
  blue: '#99D1EE',
  blue2: '#5F98D1',
  darkBlue: '#3F729B',
  greyDarkSmooth: 'rgba(65,63,96, 0.75)',
  photoColor: '#B8E7EB',
  flame: '#f38258',

  error: '#ee5a23',
  success: '#09A084',
};

export default colors;
