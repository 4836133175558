const buttons = {
  primary: {
    fontSize: 'medium-large',
    fontWeight: 'bold',
    color: 'background',
    bg: 'primary',
    borderRadius: 'default',
    outline: 'none',
  },
  secondary: {
    variant: 'buttons.primary',
    color: 'white',
    bg: 'secondary',
    borderRadius: 'medium',
  },
};

export default buttons;
